<template>
  <div class="Device111" ref="vlbox">
    <div class="index_main" ref="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    <div class="index_right">
        <p class="search">
        <el-input
          type="text"
          size="mini" clearable
          v-model="searchForm.name"
          placeholder=" 请输入关键字"
        ></el-input>
        <button class="btn1" v-if="permissoin.includes('query')" @click="currentPage2=1;getData()">
          <i class="el-icon-search"></i>
          查询
        </button>
         <button class="btn2" v-if="permissoin.includes('add')" @click="modal=1;modalState=0">
          <i class="el-icon-plus"></i>
          新增
        </button>
      </p>
      <div class="table srcollstyle">
           <el-table :data="tableData" style="width:98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
              {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
            </template>
            </el-table-column>
            <el-table-column prop="name" label="审核组名称" width="200px">
            </el-table-column>
            <el-table-column prop="type" label="审核组类型">
            </el-table-column>
            <el-table-column  label="审核组人员" width="1000px" show-overflow-tooltip="true">
              <template slot-scope="scope">
                <el-button
                v-for="item in scope.row.userNames" :key='item'
                plain
                type="primary"
                size="mini"
                >{{item}}</el-button>        
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    v-if="permissoin.includes('setDepartmentUsers')"
                      icon="el-icon-edit-outline"
                      :command="{ num: 2, data: scope.row }"
                      >设置人员</el-dropdown-item>
                       <el-dropdown-item
                      icon="el-icon-delete"
                      v-if="permissoin.includes('del')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          prev-text="上一页"
          next-text="下一页"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    </div>
    <div class="indexModal" v-if="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState==1">{{form.name}}</span>
          <span v-if="modalState==0">新增审核组</span>
          <span @click="modal = 0;form={}" class="pointer">
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
          <div class="input_item" v-if="modalState==0">
            <p >
              审核组名</p>
              <el-input
              v-model="form.name"
          type="text"
          size="mini"
          placeholder=" 请输入审核组名称"
        ></el-input>
            
            </div>

            <div class="input_item">
            <p>选择人员</p>
             <el-select v-model="form.userId" size="mini" multiple placeholder="请选择" @change="cg">
              <el-option
                v-for="item in option5"
                :key="item.userId"
                :label="item.nickName"
                :value="item.userId">
              </el-option>
            </el-select>
            </div>
            <div class="input_item" v-if="modalState==0">
            <p >
              审核组类型</p>
              <el-input
              v-model="form.type"
          type="text"
          size="mini"
          placeholder=" 请输入数字"
        ></el-input>
            
            </div>

          </div>
        <div class="foot">
          <button class="finish" @click="onsubmit">完成</button>
        </div>
        </div>
        
    </div>
  </div>
</template>

<script>
import {
  getDepartmentList,userGetAll,setDepartmentUsers,addDepartment,delDepartment
} from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      form: {state:0},
      info: 1,
      currentPage2:1,
      treeData:[],
      hide:1,
      modal:0,
      treeList:[],
      option5:[],
      total:0,
      modalState:0,
      searchForm:{},
      permissoin:[],
      projectP:-1,
      dialogVisible:false,
      netArrUpdate:[{id:0}],
      ruleForm:{
        pwd:123456
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      timeOut:0,
      treedata:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      tableData: [],
        rules: {
          orgId: [
            { required: true, message: '选择机构', trigger: 'blur' },
          ],
          }
    };
  },
  methods: {
    getData(){
      let data={
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: 10
      }    
      getDepartmentList(data).then(res=>{
        if(res.code==200){
          this.tableData=res.data.list
          this.total=res.data.total-0
        }else{
          this.tableData=[]
          this.total=0
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
     deletePro(){
      let data ={type:this.form.type}
        delDepartment(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:res.msg
            })
            this.dialogVisible=false
            this.getData();
          }else{
           this.$message.error(res.data.msg)
          }
        })
    },
    cg(){
      this.$forceUpdate()
    },
    onsubmit(){
      
      let data={
        type:this.form.type,
       name:this.form.name,
        userIds:this.form.userId.join(','),
      }
      if(this.modalState==1){
         setDepartmentUsers(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:'操作成功'
            })
            this.modal=0
            this.form={}
            this.getData()
          }else{
            this.$message.error(res.data.msg)
          }
      })
      }else if(this.modalState==0){
         addDepartment(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:'操作成功'
            })
            this.modal=0
            this.form={}
            this.getData()
          }else{
            this.$message.error(res.data.msg)
          }
      })
      }
     
    },
    handleCommand(d){
      if(d.num==2){
        this.modal=1
        this.modalState=1
        this.form=d.data
        this.form.userId=d.data.userIds.split(',')
      }else if(d.num==3){
        this.form=d.data
        this.dialogVisible=true
      }
    }
  },
  mounted() {
    this.getData()
    //获取权限
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){
        this.permissoin.push(item.permission)    
      }
    })
    userGetAll({pageSize:0}).then((res) => {
      if (res.code == 200) {
        this.option5 = res.data.list;
      }
    });
  },
  
};
</script>

<style lang="less" >
.Device111{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search{
      margin: 22px 0;
      .el-input{
        width: 208px;
        margin-right: 20px;
      }
      .el-date-editor{
        margin-right: 20px;
      }
    }
    .table{
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide{
      position: absolute;
      top:48%;
      left: 0px;
      cursor: pointer;
    }
  }
   .indexModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
    display: flex;
    align-items: center;
    .inner {
      width: 450px;
      height: 400px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 1px 1px 10px #888888;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .modal_item3 {
        padding: 0 40px;
        margin: 24px 0;
        .input_item {
          display: flex;
          margin-bottom: 13.5px;
          p {
            width: 80px;
            line-height: 28px;
          }
          .el-input {
            width: 280px;
          }
          .el-select {
            width: 280px;
          }
        }
      }
      .foot {
        width: 100%;
        position: absolute;
        bottom: 24px;
        .finish {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #d62829;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
        .confirm_delete {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #ff0101;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>